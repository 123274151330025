import type { FullAgent } from "@/types/agent";
import { StyleChatColor } from "@/types/agent";
import { Controller, type UseFormReturn } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { ColorPicker } from "@/components/AgentForm/components/Deploy/components/Integrations/WebsiteEmbedding/ColorPicker";
import { convertHexToRgb } from "@/utils/convertHexToRgb";

type BotColourProps = { form: UseFormReturn<FullAgent["publish"]> };

export const BotColour = ({ form }: BotColourProps) => {
  const { control, register, getValues } = form;

  const [customColor, setCustomColor] = useState<string>();

  useEffect(() => {
    const chatColor = getValues().chatStyle.color;
    const isCustomColor = !Object.values(StyleChatColor).includes(chatColor);

    if (!isCustomColor) {
      return;
    }

    setCustomColor(chatColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomColorInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCustomColor(e.target.value.toUpperCase());

  return (
    <div>
      <Label className="text-neutral-400">Colour Theme</Label>
      <div className="flex w-full flex-wrap items-center justify-center gap-4 rounded-md bg-neutral-50 px-6 py-4">
        <div className="mt-2 grid grid-cols-2 gap-4 xs:grid-cols-4">
          <Controller
            control={control}
            {...register("chatStyle.color")}
            render={({ field: { value, onChange } }) => (
              <>
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Neutral}
                  checked={value === StyleChatColor.Neutral.toString()}
                  label="Neutral"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-gray-300 bg-gray-300 bg-opacity-30 hover:border-gray-300 data-[state=checked]:bg-gray-300 data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-gray-300"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Black}
                  checked={value === StyleChatColor.Black.toString()}
                  label="Black"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#000000] bg-[#000000] bg-opacity-30 hover:border-[#000000] data-[state=checked]:bg-[#000000] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#000000]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Yellow}
                  checked={value === StyleChatColor.Yellow.toString()}
                  label="Yellow"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#F1C21F] bg-[#F1C21F] bg-opacity-30 hover:border-[#F1C21F] data-[state=checked]:bg-[#F1C21F] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#F1C21F]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Red}
                  checked={value === StyleChatColor.Red.toString()}
                  label="Red"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#E84150] bg-[#E84150] bg-opacity-30 hover:border-[#E84150] data-[state=checked]:bg-[#E84150] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#E84150]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Orange}
                  checked={value === StyleChatColor.Orange.toString()}
                  label="Orange"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#EE9B01] bg-[#EE9B01] bg-opacity-30 hover:border-[#EE9B01] data-[state=checked]:bg-[#EE9B01] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#EE9B01]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Blue}
                  checked={value === StyleChatColor.Blue.toString()}
                  label="Blue"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#3DA3D4] bg-[#3DA3D4] bg-opacity-30 hover:border-[#3DA3D4] data-[state=checked]:bg-[#3DA3D4] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#3DA3D4]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Green}
                  checked={value === StyleChatColor.Green.toString()}
                  label="Green"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#649F3B] bg-[#649F3B] bg-opacity-30 hover:border-[#649F3B] data-[state=checked]:bg-[#649F3B] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#649F3B]"
                />
                <Checkbox
                  onClick={onChange}
                  value={StyleChatColor.Purple}
                  checked={value === StyleChatColor.Purple.toString()}
                  label="Purple"
                  labelClassName="text-sm font-medium text-neutral-500"
                  className="size-[25px] rounded border-[#8B56AE] bg-[#8B56AE] bg-opacity-30 hover:border-[#8B56AE] data-[state=checked]:bg-[#8B56AE] data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-[#8B56AE]"
                />
              </>
            )}
          />
        </div>
        <div className="flex w-full items-center justify-center gap-4">
          <Controller
            control={control}
            {...register("chatStyle.color")}
            render={({ field: { value, onChange } }) => (
              <>
                <Checkbox
                  onClick={onChange}
                  value={customColor}
                  checked={value === customColor}
                  className="size-[25px] rounded border-custom-theme-border-color bg-custom-theme-background-color bg-opacity-30 hover:border-custom-theme-border-color data-[state=checked]:bg-custom-theme-background-color data-[state=checked]:bg-opacity-30 data-[state=checked]:text-primary-black hover:data-[state=checked]:bg-custom-theme-color"
                  style={{ "--custom-theme-color": convertHexToRgb(customColor) } as CSSProperties}
                />

                <ColorPicker value={customColor} onChange={onCustomColorInputChange} />
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};
